import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import Button from "../../../Components/Button/Button";
import useGstDetails from "./useGstDetails";
import GstValidationModal from "../GstValidationModal";

const GstDetails = ({
  firstName,
  gstNumber,
  gstPhoneNumber,
  gstEmail,
  registeredAddress,
  state,
  isGstDraft,
  fetchGstCredentials,
  planName,
  planAmount,
  gstAmount,
}) => {
  const {
    subTitleFontSize,
    fontSize,
    validateGst,
    open,
    handleModalOpen,
    handleModalClose,
  } = useGstDetails({ fetchGstCredentials });
  console.log(planName, planAmount, gstAmount);
  return (
    <Box>
      <GstValidationModal
        gstCredentials={{
          firstName,
          gstNumber,
          gstPhoneNumber,
          gstEmail,
          registeredAddress,
          state,
        }}
        open={open}
        handleClose={handleModalClose}
        validateGst={validateGst}
      />
      <Grid
        container
        display={"flex"}
        justifyContent={"center"}
        spacing={2}
        my={6}
      >
        <Grid item xs={12}>
          <Grid container rowSpacing={4}>
            <Grid item xs={6} display={"flex"} justifyContent={"start"}>
              <Typography fontSize={fontSize} fontWeight={700}>
                First Name:
              </Typography>
            </Grid>
            <Grid item xs={6} display={"flex"} justifyContent={"start"}>
              <Typography fontSize={fontSize}>{firstName || "N/A"}</Typography>
            </Grid>
            <Grid item xs={6} display={"flex"} justifyContent={"start"}>
              <Typography fontSize={fontSize} fontWeight={700}>
                GST Number:
              </Typography>
            </Grid>
            <Grid item xs={6} display={"flex"} justifyContent={"start"}>
              <Typography fontSize={fontSize}>{gstNumber || "N/A"}</Typography>
            </Grid>
            <Grid item xs={6} display={"flex"} justifyContent={"start"}>
              <Typography fontSize={fontSize} fontWeight={700}>
                GST Phone Number:
              </Typography>
            </Grid>
            <Grid item xs={6} display={"flex"} justifyContent={"start"}>
              <Typography fontSize={fontSize}>
                {gstPhoneNumber || "N/A"}
              </Typography>
            </Grid>
            <Grid item xs={6} display={"flex"} justifyContent={"start"}>
              <Typography fontSize={fontSize} fontWeight={700}>
                GST Email:
              </Typography>
            </Grid>
            <Grid item xs={6} display={"flex"} justifyContent={"start"}>
              <Typography fontSize={fontSize}>{gstEmail || "N/A"}</Typography>
            </Grid>
            <Grid item xs={6} display={"flex"} justifyContent={"start"}>
              <Typography fontSize={fontSize} fontWeight={700}>
                Registered Address:
              </Typography>
            </Grid>
            <Grid item xs={6} display={"flex"} justifyContent={"start"}>
              <Typography fontSize={fontSize}>
                {registeredAddress || "N/A"}
              </Typography>
            </Grid>
            <Grid item xs={6} display={"flex"} justifyContent={"start"}>
              <Typography fontSize={fontSize} fontWeight={700}>
                State:
              </Typography>
            </Grid>
            <Grid item xs={6} display={"flex"} justifyContent={"start"}>
              <Typography fontSize={fontSize}>{state || "N/A"}</Typography>
            </Grid>
            <Grid mt={4} item xs={12} display={"flex"} justifyContent={"start"}>
              <Typography fontSize={subTitleFontSize} fontWeight={700}>
                Plan Details:
              </Typography>
            </Grid>
            <Grid item xs={6} display={"flex"} justifyContent={"start"}>
              <Typography fontSize={fontSize} fontWeight={700}>
                Name:
              </Typography>
            </Grid>
            <Grid item xs={6} display={"flex"} justifyContent={"start"}>
              <Typography fontSize={fontSize}>{planName || "N/A"}</Typography>
            </Grid>
            <Grid item xs={6} display={"flex"} justifyContent={"start"}>
              <Typography fontSize={fontSize} fontWeight={700}>
                Plan amount:
              </Typography>
            </Grid>
            <Grid item xs={6} display={"flex"} justifyContent={"start"}>
              <Typography fontSize={fontSize}>{planAmount || "N/A"}</Typography>
            </Grid>
            <Grid item xs={6} display={"flex"} justifyContent={"start"}>
              <Typography fontSize={fontSize} fontWeight={700}>
                GST Amount:
              </Typography>
            </Grid>
            <Grid item xs={6} display={"flex"} justifyContent={"start"}>
              <Typography fontSize={fontSize}>{gstAmount || "N/A"}</Typography>
            </Grid>
          </Grid>
        </Grid>
        {firstName &&
          gstNumber &&
          gstPhoneNumber &&
          gstEmail &&
          registeredAddress &&
          state &&
          isGstDraft && (
            <Grid item xs={12} display={"flex"} justifyContent={"end"}>
              <Button text="Validate" onClick={() => handleModalOpen()} />
            </Grid>
          )}
        {!isGstDraft && (
          <Grid mt={4} item xs={12} display={"flex"} justifyContent={"end"}>
            <Typography
              sx={{ textDecoration: "underline" }}
              fontSize={fontSize}
            >
              Your invoice will be ready for download in the near future!
            </Typography>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default GstDetails;
