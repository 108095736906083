import React, { useMemo, useRef } from "react";
import Button from "../../Components/Button/Button";
import "../Philosophy Page/style.scss";
import "./style.scss";
import { Box, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import { SlideUp } from "../../Components/SlideUp";
const REACT_APP_WHATSAPP_LINK = process.env.REACT_APP_WHATSAPP_LINK;

export default function Subscriptions() {
  const theme = useTheme();
  const isSmDown = useMediaQuery(theme.breakpoints.down("sm"));
  const isMdDown = useMediaQuery(theme.breakpoints.down("md"));
  const headerFontSize = useMemo(
    () => (isMdDown ? (isSmDown ? "30px" : "45px") : "75px"),
    [isMdDown, isSmDown],
  );
  const paragraphFontSize = useMemo(
    () => (isMdDown ? (isSmDown ? "20px" : "22px") : "28px"),
    [isMdDown, isSmDown],
  );
  const titleRef = useRef(null);
  const subStateRef = useRef(null);

  return (
    <div className="container">
      <Box height="90vh" display="flex" justifyContent="center">
        <Box
          width={isSmDown ? "90vw" : "75vw"}
          display="flex"
          flexDirection="column"
          justifyContent="space-around"
        >
          <Grid
            container
            textTransform="capitalize"
            overflow="hidden"
            ref={titleRef}
            display="flex"
            flexDirection="column"
          >
            <Grid item>
              <SlideUp
                containerRef={titleRef}
                content={
                  <Typography
                    fontWeight={600}
                    lineHeight={1.5}
                    fontSize={headerFontSize}
                  >
                    Subscriptions
                  </Typography>
                }
              />
            </Grid>
          </Grid>
          <Grid
            container
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            spacing={2}
          >
            <Grid item>
              <Grid
                container
                display="flex"
                flexDirection="column"
                alignItems="center"
                ref={subStateRef}
                overflow="hidden"
                spacing={2}
              >
                <Grid item>
                  <SlideUp
                    containerRef={subStateRef}
                    content={
                      <Typography
                        fontWeight={600}
                        lineHeight={1}
                        fontSize={headerFontSize}
                      >
                        Subscriptions
                      </Typography>
                    }
                  />
                </Grid>
                <Grid item>
                  <SlideUp
                    containerRef={subStateRef}
                    content={
                      <Typography
                        fontWeight={600}
                        fontSize={headerFontSize}
                        lineHeight={1}
                      >
                        Closed
                      </Typography>
                    }
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Box
        height="100vh"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <Box width={isSmDown ? "90vw" : "75vw"} display="flex">
          <Grid container spacing={2} display="flex">
            <Grid item lg={2} md={2} sm={3}>
              <Typography fontSize={20} fontWeight={600} lineHeight={1.2}>
                Overview
              </Typography>
            </Grid>
            <Grid item lg={8} md={10} sm={9}>
              <Grid container spacing={2} display="flex" flexDirection="column">
                <Grid item>
                  <Typography
                    fontSize={paragraphFontSize}
                    fontWeight={500}
                    lineHeight={1.2}
                  >
                    Darkhorsestocks was started with a simple aim of bringing
                    amazing fundamentally strong comapnies to users attention
                    with in depth information about the company.
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    fontSize={paragraphFontSize}
                    fontWeight={500}
                    lineHeight={1.2}
                  >
                    Unlike other advisories, we are not in this for money and
                    that is why we open for subscriptions only twice a year and
                    that to for a limited period of a month only.
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    fontSize={paragraphFontSize}
                    fontWeight={500}
                    lineHeight={1.2}
                  >
                    Keeping subscritiptions open all round the year increases a
                    lot of work. Instead of that
                    <strong>
                      {" "}
                      we want to focus on providing quality research
                    </strong>
                    and amazing companies. Hope you understand.
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Box>

      <Box
        height="100vh"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <Box width={isSmDown ? "90vw" : "75vw"} display="flex">
          <Grid
            container
            display="flex"
            textAlign="center"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            spacing={2}
          >
            <Grid item>
              <Typography
                fontSize={paragraphFontSize}
                fontWeight={600}
                lineHeight={1}
              >
                Subscriptions will reopen for this period.
              </Typography>
            </Grid>
            <Grid item>
              <Grid
                container
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
              >
                <Grid item>
                  <Typography
                    fontSize={headerFontSize}
                    fontWeight={600}
                    lineHeight={1}
                  >
                    01-Nov-2024
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    fontSize={headerFontSize}
                    fontWeight={600}
                    lineHeight={1}
                  >
                    to
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    fontSize={headerFontSize}
                    fontWeight={600}
                    lineHeight={1}
                  >
                    15-Nov-2024
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Box
        height="100vh"
        flexDirection="column"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Box width={isSmDown ? "85vw" : "75vw"}>
          <Grid
            container
            textTransform="capitalize"
            overflow="hidden"
            display="flex"
            flexDirection="column"
            mb={5}
          >
            <Grid item lg={8} md={12} sm={12}>
              <Typography
                fontWeight={600}
                lineHeight={1}
                fontSize={headerFontSize}
              >
                What happens when you subscribe?
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            display="flex"
            flexDirection="column"
            alignItems="start"
            justifyContent="center"
            spacing={5}
          >
            <Grid item lg={8} md={12} sm={12}>
              <Typography
                fontWeight={300}
                fontSize={paragraphFontSize}
                lineHeight={1.2}
              >
                When you subscribe you not only get amazing fundamentally strong
                company every week but you support darkhorsestocks in our future
                endavours, helping us to carry out quality research and devlop
                more amazing tools like Fundalysis.
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Box
        height="100vh"
        flexDirection="column"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Box width={isSmDown ? "85vw" : "75vw"}>
          <Grid
            container
            textTransform="capitalize"
            overflow="hidden"
            display="flex"
            flexDirection="column"
            mb={5}
          >
            <Grid item lg={8} md={12} sm={12}>
              <Typography
                fontWeight={600}
                lineHeight={1}
                fontSize={headerFontSize}
              >
                What to do now?
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            display="flex"
            flexDirection="column"
            alignItems="start"
            justifyContent="center"
            spacing={5}
          >
            <Grid item lg={8} md={12} sm={12}>
              <Typography
                fontWeight={300}
                fontSize={paragraphFontSize}
                lineHeight={1.2}
              >
                Subscribe to our free plan for all small users where we suggest
                one company every month or around 10-12 companies every year and
                one infographic every week.
              </Typography>
            </Grid>
            <Grid item>
              <a href={REACT_APP_WHATSAPP_LINK}>
                <Button text="WhatsApp" className={" "}></Button>
              </a>
            </Grid>
            <Grid item>
              <Typography fontWeight={500} lineHeight={1}>
                No spam advertising
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </div>
  );
}
