import { configureStore } from "@reduxjs/toolkit";
import { homeApi } from "../redux/HomeAPI";
import { socketApi } from "../redux/WebSocket";
import selectedReducer from "../redux/Selected";
import ThemeReducer from "../redux/Theme";
import { blogsAPI } from "../redux/BlogsAPI";
import { searchAPI } from "../redux/SearchAPI";
import { companyAPI } from "../redux/CompanyAPI";
import { plansApi } from "../redux/PlansAPI";
import { razorpayApi } from "../redux/RazorpayAPI";
import { referralsApi } from "../redux/ReferralsAPI";
import { paytmApi } from "../redux/PaytmAPI";
import { authApi } from "../redux/AuthAPI";
import { usersApi } from "../redux/UserAPI";
import { tagsAPI } from "../redux/TagsAPI";
import { SectorsAPI } from "../redux/SectorsAPI";

const store = configureStore({
  reducer: {
    selected: selectedReducer,
    theme: ThemeReducer,
    [homeApi.reducerPath]: homeApi.reducer,
    [blogsAPI.reducerPath]: blogsAPI.reducer,
    [companyAPI.reducerPath]: companyAPI.reducer,
    [socketApi.reducerPath]: socketApi.reducer,
    [searchAPI.reducerPath]: searchAPI.reducer,
    [plansApi.reducerPath]: plansApi.reducer,
    [razorpayApi.reducerPath]: razorpayApi.reducer,
    [referralsApi.reducerPath]: referralsApi.reducer,
    [paytmApi.reducerPath]: paytmApi.reducer,
    [authApi.reducerPath]: authApi.reducer,
    [tagsAPI.reducerPath]: tagsAPI.reducer,
    [SectorsAPI.reducerPath]: SectorsAPI.reducer,
    [usersApi.reducerPath]: usersApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      usersApi.middleware,
      SectorsAPI.middleware,
      tagsAPI.middleware,
      usersApi.middleware,
      authApi.middleware,
      homeApi.middleware,
      socketApi.middleware,
      blogsAPI.middleware,
      searchAPI.middleware,
      companyAPI.middleware,
      plansApi.middleware,
      razorpayApi.middleware,
      referralsApi.middleware,
      paytmApi.middleware,
    ),
});

export default store;
