import React, { useEffect, useMemo, useRef, useState } from "react";
import { AiOutlineArrowDown } from "react-icons/ai";
import AccordionCard from "../../Components/Accordion Card/AccordionCard";
import Button from "../../Components/Button/Button";
import CustomAccordion from "../../Components/Accordion/CustomAccordion";
import "./style.scss";
import { useHistory } from "react-router-dom";
import { Box, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import { SlideUp } from "../../Components/SlideUp";
import advisoriesList from "./advisoriesList.json";
// import axios from "axios";

export default function ListPage() {
  const history = useHistory();
  // const [advisoriesList, setAdvisoriesList] = useState([]);
  // useEffect(() => {
  //   axios
  //     .get(process.env.REACT_APP_ADVISORIES_LINK)
  //     .then((res) => setAdvisoriesList(res.data))
  //     .catch((e) => alert(e.message));
  // }, []);
  const [expanded, setExpanded] = useState(false);
  const [expansion, setExpansion] = useState(3);
  const handleMore = () => {
    setExpansion((prev) => prev + 3);
  };
  const handleLess = () => {
    setExpansion((prev) => (prev >= 6 ? prev - 3 : prev - (prev % 3)));
  };

  useEffect(() => {
    if (expansion <= 6) setExpanded(false);
    else setExpanded(true);
  }, [expansion]);
  const advisoriesListToShow = useMemo(
    () => advisoriesList?.slice(0, expansion),
    [expansion],
  );
  const theme = useTheme();
  const isSmDown = useMediaQuery(theme.breakpoints.down("sm"));
  const isMdDown = useMediaQuery(theme.breakpoints.down("md"));
  const headerFontSize = useMemo(
    () => (isMdDown ? (isSmDown ? "30px" : "45px") : "75px"),
    [isMdDown, isSmDown],
  );

  const paragraphFontSize = useMemo(
    () => (isMdDown ? (isSmDown ? "20px" : "22px") : "28px"),
    [isMdDown, isSmDown],
  );
  const mediumHeaderFontSize = useMemo(
    () => (isMdDown ? (isSmDown ? "25px" : "40px") : "55px"),
    [isMdDown, isSmDown],
  );
  const containerRef = useRef(null);
  return (
    <Box>
      <Box height="90vh" display="flex" justifyContent="center">
        <Box
          width={isSmDown ? "90vw" : "75vw"}
          display="flex"
          flexDirection="column"
          justifyContent="space-around"
        >
          <Grid
            container
            textTransform="capitalize"
            overflow="hidden"
            ref={containerRef}
            display="flex"
            flexDirection="column"
          >
            <Grid item>
              <SlideUp
                containerRef={containerRef}
                content={
                  <Typography
                    fontWeight={600}
                    lineHeight={1}
                    fontSize={headerFontSize}
                  >
                    List of <br /> advisories
                  </Typography>
                }
              />
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Box
        height="100vh"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <Box width={isSmDown ? "90vw" : "75vw"} display="flex">
          <Grid container spacing={2} display="flex">
            <Grid item lg={2} md={2} sm={3}>
              <Typography fontSize={20} fontWeight={600} lineHeight={1.2}>
                Overview
              </Typography>
            </Grid>
            <Grid item lg={8} md={10} sm={9}>
              <Typography
                fontSize={paragraphFontSize}
                fontWeight={300}
                lineHeight={1.2}
              >
                This list compiles internet advisories and their prices sourced
                from various online platforms and direct inquiries. Not
                exhaustive, prices and services may vary. It serves as a
                resource for those seeking alternatives to Dark Horse Stocks. We
                value all services equally and respect your freedom to explore
                and subscribe elsewhere. Our free plan offers about 10 articles
                per year. If any subscription service is missing, please contact
                us at{" "}
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  className="line-animation-3"
                  style={{ color: "#838282", textTransform: "none !important" }}
                  href={process.env.REACT_APP_EMAIL_LINK}
                >
                  Support@darkhorsestocks.in
                </a>{" "}
                with the service's name, website, offered services, and pricing.
                We're delighted to review and update our website accordingly."
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Box
        flexDirection="column"
        display="flex"
        alignItems="center"
        justifyContent="center"
        mb="20vh"
      >
        <Box width={isSmDown ? "85vw" : "75vw"}>
          <Grid container display="flex" flexDirection="column" spacing={5}>
            <Grid item lg={12} md={12} sm={12}>
              <Grid
                container
                fontSize={mediumHeaderFontSize}
                display="flex"
                justifyContent="space-between"
              >
                <Grid item>
                  Name{" "}
                  <AiOutlineArrowDown className="icon-mobile" fontSize={35} />
                </Grid>
                <Grid item display="flex" flexDirection="column">
                  <Box>
                    Price{" "}
                    <AiOutlineArrowDown className="icon-mobile" fontSize={35} />
                  </Box>
                  <Typography>per year</Typography>
                </Grid>
              </Grid>
              <Grid
                container
                fontSize={paragraphFontSize}
                display="flex"
                flexDirection="column"
              >
                {advisoriesListToShow &&
                  advisoriesListToShow.length !== 0 &&
                  advisoriesListToShow.map((item, idx) => (
                    <Grid item key={idx}>
                      <CustomAccordion
                        right={item.name}
                        left={`Rs ${item.chargesPerYear}`}
                        card={
                          <AccordionCard
                            approx={item.pricePerStock}
                            numberOfStocks={item.noOfStocks}
                            plans={item.plans}
                            sebiRegistered={item.sebiRegistration}
                            freeTrial={item.freeTrial}
                            typeOfService={item.typeOfService}
                          />
                        }
                      />
                    </Grid>
                  ))}
              </Grid>
              <Grid
                container
                spacing={2}
                rowSpacing={8}
                display="flex"
                justifyContent="center"
                pt={4}
              >
                {advisoriesList?.length > expansion && (
                  <Grid item>
                    <Button onClick={handleMore} text="Show More" />
                  </Grid>
                )}
                {expanded && (
                  <Grid item>
                    <Button onClick={handleLess} text="Show Less" />
                  </Grid>
                )}
              </Grid>
              <Grid
                container
                fontSize={paragraphFontSize}
                display="flex"
                justifyContent="center"
                mt={"3vh"}
              >
                <Grid item>
                  <Button
                    text="Go back"
                    onClick={() => {
                      history.push("/pricing");
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
}
