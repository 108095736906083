import React, {
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
} from "react";
import Button from "../../Components/Button/Button";
import "./style.scss";
import { Box, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import useHomePage from "./useHomePage";
import LinearProgressBar from "../../Components/LinearProgressBar";
import LatestIdeaSkeleton from "../../Components/Skeleton card/LatestIdeaSkeleton";
import SkeletonSlider from "../../Components/Skeleton card/SkeletonSlider";
import SecImage from "../../Assets/image section.png";
import HomePageSlider from "../../Components/HomePageSlider/HomePageSlider";
import { SlideUp } from "../../Components/SlideUp";
import LatestIdeaCardDescription from "./components/LatestIdeaCardDescription";
import AuthContext from "../Auth/AuthContext";
import LatestIdeaCardDummy from "./components/LatestIdeaCardDescription/LatestIdeaCardDummy";
import SebiConditionsModal from "./components/SebiConditionsModal";
import HeaderText from "./components/HeaderText";
const REACT_APP_TELEGRAM_LINK = process.env.REACT_APP_TELEGRAM_LINK;
const REACT_APP_WHATSAPP_LINK = process.env.REACT_APP_WHATSAPP_LINK;

const HomePage = () => {
  const { isLoading, isSuccess, onClickHandler, latestIdea, homepageSections } =
    useHomePage();
  const { isSubscribed, isAuthenticated } = useContext(AuthContext);
  const containerRef = useRef(null);
  const LatestIdeaRef = useRef(null);
  const theme = useTheme();
  const isSmDown = useMediaQuery(theme.breakpoints.down("sm"));
  const isMdDown = useMediaQuery(theme.breakpoints.down("md"));
  const isLgDown = useMediaQuery(theme.breakpoints.down("lg"));
  const headerFontSize = useMemo(
    () => (isMdDown ? (isSmDown ? "30px" : "45px") : "75px"),
    [isMdDown, isSmDown],
  );
  const mediumHeaderFontSize = useMemo(
    () => (isMdDown ? (isSmDown ? "25px" : "40px") : "55px"),
    [isMdDown, isSmDown],
  );
  const paragraphFontSize = useMemo(
    () => (isMdDown ? (isSmDown ? "20px" : "22px") : "28px"),
    [isMdDown, isSmDown],
  );
  const smallParagraphFontSize = useMemo(
    () => (isMdDown ? (isSmDown ? "16px" : "21px") : "24px"),
    [isMdDown, isSmDown],
  );
  const dateOptions = {
    weekday: "short",
    month: "short",
    day: "numeric",
    year: "numeric",
  };
  const unauthorizedIdeaBySubscription = useMemo(
    () => latestIdea?.paid === "Paid" && !isSubscribed,
    [latestIdea, isSubscribed],
  );

  const [open, setOpen] = useState(false);
  const handleModalOpen = useCallback(() => {
    setOpen(true);
  }, []);
  const handleModalClose = useCallback(() => {
    setOpen(false);
  }, []);
  return (
    <Box>
      <SebiConditionsModal open={open} onClose={handleModalClose} />
      {!isAuthenticated && (
        <Box
          height="90vh"
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
        >
          <Box width={isSmDown ? "90vw" : "75vw"}>
            <Grid
              container
              overflow="hidden"
              ref={containerRef}
              display="flex"
              flexDirection="column"
            >
              <Grid
                container
                display="flex"
                flexDirection="column"
                alignItems="start"
                justifyContent="center"
                spacing={6}
              >
                <Grid item>
                  <Grid container display="flex" flexDirection="column">
                    {!isLgDown ? (
                      <HeaderText
                        containerRef={containerRef}
                        headerFontSize={headerFontSize}
                        textList={[
                          "Suggesting one",
                          "fundamentally strong",
                          "company every week",
                        ]}
                      />
                    ) : (
                      <HeaderText
                        containerRef={containerRef}
                        headerFontSize={headerFontSize}
                        textList={[
                          "Suggesting one",
                          "fundamentally",
                          "strong company",
                          "every week",
                        ]}
                      />
                    )}
                  </Grid>
                </Grid>

                <Grid item>
                  <SlideUp
                    containerRef={containerRef}
                    content={
                      <Typography fontWeight={500} lineHeight={1}>
                        Join us for weekly updates
                      </Typography>
                    }
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid
              container
              display="flex"
              spacing={2}
              mt={1}
              flexDirection={isMdDown ? "column" : "row"}
            >
              <Grid item>
                <a href={REACT_APP_WHATSAPP_LINK}>
                  <Button text="WhatsApp" />
                </a>
              </Grid>
              <Grid item>
                <a href={REACT_APP_TELEGRAM_LINK}>
                  <Button text="Telegram" />
                </a>
              </Grid>
            </Grid>
            <Grid container pt={8} display={"flex"} justifyContent={"end"}>
              <Grid item>
                <SlideUp
                  containerRef={containerRef}
                  content={
                    <Typography
                      onClick={handleModalOpen}
                      fontWeight={500}
                      sx={{ cursor: "pointer" }}
                      lineHeight={1}
                    >
                      We are not sebi registered
                    </Typography>
                  }
                />
              </Grid>
            </Grid>
          </Box>
        </Box>
      )}
      {isLoading && !isSuccess ? (
        <>
          <LinearProgressBar />
          <LatestIdeaSkeleton />
          <SkeletonSlider />
          <SkeletonSlider />
          <SkeletonSlider />
        </>
      ) : (
        <>
          {latestIdea && (
            <Box
              marginY={isLgDown ? "20vh" : undefined}
              height={isLgDown ? undefined : "100vh"}
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
            >
              <Box width={isSmDown ? "90vw" : "75vw"}>
                <Grid
                  container
                  display="flex"
                  flexDirection="column"
                  spacing={8}
                >
                  <Grid item ref={LatestIdeaRef} overflow="hidden">
                    <SlideUp
                      containerRef={LatestIdeaRef}
                      content={
                        <Typography
                          fontWeight={600}
                          lineHeight={1}
                          fontSize={headerFontSize}
                          textTransform="capitalize"
                        >
                          Latest Darkhorsestock Article
                        </Typography>
                      }
                    />
                  </Grid>
                  <Grid
                    item
                    onClick={onClickHandler}
                    data-cursor-text="Read Report"
                    sx={{ cursor: "pointer" }}
                  >
                    <Grid
                      container
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      spacing={8}
                    >
                      {isLgDown ? (
                        <>
                          <Grid item lg={6} md={12} sm={12}>
                            <img
                              src={
                                latestIdea &&
                                latestIdea.image &&
                                latestIdea.image.length
                                  ? latestIdea.image
                                  : SecImage
                              }
                              alt="Hero"
                              className="hero-img"
                            />
                          </Grid>
                          <Grid
                            position="relative"
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            item
                            lg={6}
                            md={12}
                            sm={12}
                          >
                            {unauthorizedIdeaBySubscription ||
                            !isAuthenticated ? (
                              <LatestIdeaCardDummy
                                unauthorizedIdeaBySubscription={
                                  unauthorizedIdeaBySubscription
                                }
                                isAuthenticated={isAuthenticated}
                                dateOptions={dateOptions}
                                mediumHeaderFontSize={mediumHeaderFontSize}
                                paragraphFontSize={paragraphFontSize}
                                smallParagraphFontSize={smallParagraphFontSize}
                              />
                            ) : (
                              <LatestIdeaCardDescription
                                dateOptions={dateOptions}
                                latestIdea={latestIdea}
                                mediumHeaderFontSize={mediumHeaderFontSize}
                                paragraphFontSize={paragraphFontSize}
                                smallParagraphFontSize={smallParagraphFontSize}
                              />
                            )}
                          </Grid>
                        </>
                      ) : (
                        <>
                          <Grid
                            position="relative"
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            item
                            lg={6}
                            md={12}
                            sm={12}
                          >
                            {unauthorizedIdeaBySubscription ||
                            !isAuthenticated ? (
                              <LatestIdeaCardDummy
                                unauthorizedIdeaBySubscription={
                                  unauthorizedIdeaBySubscription
                                }
                                isAuthenticated={isAuthenticated}
                                dateOptions={dateOptions}
                                mediumHeaderFontSize={mediumHeaderFontSize}
                                paragraphFontSize={paragraphFontSize}
                                smallParagraphFontSize={smallParagraphFontSize}
                              />
                            ) : (
                              <LatestIdeaCardDescription
                                dateOptions={dateOptions}
                                latestIdea={latestIdea}
                                mediumHeaderFontSize={mediumHeaderFontSize}
                                paragraphFontSize={paragraphFontSize}
                                smallParagraphFontSize={smallParagraphFontSize}
                              />
                            )}
                          </Grid>
                          <Grid item lg={6} md={12} sm={12}>
                            <img
                              src={
                                latestIdea &&
                                latestIdea.image &&
                                latestIdea.image.length
                                  ? latestIdea.image
                                  : SecImage
                              }
                              alt="Hero"
                              className="hero-img"
                            />
                          </Grid>
                        </>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          )}
        </>
      )}
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
      >
        <Box width="90vw">
          <Grid container display="flex" flexDirection="column">
            {homepageSections &&
              homepageSections.length &&
              homepageSections.map(
                (homepageSection) =>
                  homepageSection.companyList?.length !== 0 && (
                    <Grid
                      item
                      xs={12}
                      md={12}
                      lg={12}
                      key={homepageSection.title}
                    >
                      <Box height="100vh">
                        <Grid
                          container
                          flexDirection="column"
                          display="flex"
                          alignItems="start"
                          justifyContent="center"
                          spacing={8}
                        >
                          <Grid item xs={12} md={12} lg={12}>
                            <Typography
                              fontWeight={600}
                              lineHeight={1}
                              fontSize={headerFontSize}
                              textTransform="capitalize"
                              pl={isMdDown ? 0 : 10}
                            >
                              {homepageSection.title}
                            </Typography>
                          </Grid>
                          <Grid item xs={12} md={12} lg={12}>
                            <HomePageSlider homepageSection={homepageSection} />
                          </Grid>
                        </Grid>
                      </Box>
                    </Grid>
                  ),
              )}
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};
export default HomePage;
