import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import expandedIcon from "../../Assets/Triangle light.svg";
import expandedIcon2 from "../../Assets/triangle filled.svg";

import React from "react";

export default function CustomAccordion(props) {
  const [expanded, setExpanded] = React.useState(
    props.defaultExpanded || false,
  );
  console.log(props.defaultExpanded);
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const theme = useTheme();
  const isSmDown = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Accordion
      className="container Accordion-wrapper"
      expanded={expanded === "panel1"}
      onChange={handleChange("panel1")}
    >
      <AccordionSummary
        className="AccordionSummary"
        expandIcon={
          expanded ? (
            <img
              src={expandedIcon2}
              className="expanded-icon"
              alt="expandedIcon2"
            />
          ) : (
            <img src={expandedIcon} alt="expandedIcon" />
          )
        }
      >
        <div className="d-flex justify-content-between w-100 accordion-text">
          {props.right && <h5 className="fs-30 semi-font">{props.right}</h5>}
          {props.left && <h5 className="fs-30 semi-font">{props.left}</h5>}
        </div>
      </AccordionSummary>
      <AccordionDetails
        sx={{ paddingX: isSmDown ? 1 : undefined }}
        className="AccordionDetails"
      >
        {props.card}
      </AccordionDetails>
    </Accordion>
  );
}
