import { Grid, Typography } from "@mui/material";
import React from "react";
import { SlideUp } from "../../../../Components/SlideUp";

const HeaderText = ({ containerRef, headerFontSize, textList }) => {
  return textList.map((text) => (
    <Grid item display="flex" alignItems="center">
      <SlideUp
        containerRef={containerRef}
        content={
          <Typography fontWeight={600} lineHeight={1} fontSize={headerFontSize}>
            {text}
          </Typography>
        }
      />
    </Grid>
  ));
};

export default HeaderText;
