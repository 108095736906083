import React, {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import axios from "axios";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [paid, setPaid] = useState("Free");
  const [renderApp, setRenderApp] = useState(false);
  const [user, setUser] = useState(undefined);
  const [activeSubscription, setActiveSubscription] = useState(undefined);

  const history = useHistory();

  const login = (token, history) => {
    localStorage.setItem("code", `Bearer: ${token}`);
    const blogId = localStorage.getItem("blogId");
    setIsAuthenticated(true);
    if (blogId) {
      localStorage.removeItem("blogId");
      history.push(`/blog/${blogId}`);
      window.location.reload();
      return;
    }
    const planSubsctiptionLocation = localStorage.getItem("profileLocation");
    if (planSubsctiptionLocation) {
      history.push("/profile");
      localStorage.removeItem("profileLocation");
      window.location.reload();
    } else {
      history.push("/");
      window.location.reload();
    }
  };

  const isSubscriptionOpen = useMemo(() => {
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth() + 1; // Months are zero-based, so we add 1

    // Date ranges: 01/06 to 15/06 and 15/10 to 31/10
    const range1Start = new Date(currentDate.getFullYear(), 5, 1);
    const range1End = new Date(currentDate.getFullYear(), 5, 15);
    const range2Start = new Date(currentDate.getFullYear(), 10, 1);
    const range2End = new Date(currentDate.getFullYear(), 10, 30);

    if (
      (currentMonth === 6 &&
        currentDate >= range1Start &&
        currentDate <= range1End) ||
      (currentMonth === 11 &&
        currentDate >= range2Start &&
        currentDate <= range2End)
    ) {
      return true;
    }

    return false;
  }, []);

  const logout = () => {
    localStorage.removeItem("code");
    setIsAuthenticated(false);
    window.location.reload();
  };
  const fetchMe = useCallback(() => {
    const token = localStorage.getItem("code");
    axios
      .get(`${process.env.REACT_APP_BASE_URL}user`, {
        headers: {
          Authorization: `${token}`,
        },
      })
      .then((fetchedUser) => {
        if (fetchedUser && fetchedUser.data && !fetchedUser.data.error) {
          setUser(fetchedUser.data);
          setPaid(fetchedUser.data.paid);
          setIsAuthenticated(true);
          setIsAdmin(fetchedUser.data.isAdmin);
          setIsSubscribed(
            fetchedUser.data.isAdmin || fetchedUser.data.isSubscribed,
          );
          if (fetchedUser.data.isSubscribed)
            setActiveSubscription(
              fetchedUser.data.subscription.sort((a, b) => {
                if (new Date(a.startedAt) <= new Date(b.startedAt)) {
                  return 1;
                } else return -1;
              })[0],
            );
        } else {
          setIsAuthenticated(false);
        }
        setRenderApp(true);
      })
      .catch((error) => {
        console.error(error);
        setRenderApp(true);
      });
  }, []);
  useEffect(() => {
    fetchMe();
  }, [fetchMe]);
  const handleAuthRoute = (pathname) => {
    if (isAuthenticated) history.push(pathname);
    else history.push("/login");
  };
  const handleLinkTo = useCallback(
    (pathname) => {
      if (isAuthenticated) return pathname;
      else return "/login";
    },
    [isAuthenticated],
  );
  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        isAdmin,
        isSubscriptionOpen,
        paid,
        login,
        logout,
        isSubscribed,
        setIsSubscribed,
        renderApp,
        handleAuthRoute,
        handleLinkTo,
        user,
        fetchMe,
        activeSubscription,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export { AuthProvider };
export default AuthContext;
