import React, { useCallback, useMemo, useRef } from "react";
import HeroImg from "../../Assets/hereo.png";
import Button from "../../Components/Button/Button";
import { Box, Typography, Grid, useTheme, useMediaQuery } from "@mui/material";
import { SlideUp } from "../../Components/SlideUp";
import axios from "axios";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

export default function Categories() {
  const history = useHistory();
  const theme = useTheme();
  const isSmDown = useMediaQuery(theme.breakpoints.down("sm"));
  const isMdDown = useMediaQuery(theme.breakpoints.down("md"));
  const headerFontSize = useMemo(
    () => (isMdDown ? (isSmDown ? "30px" : "45px") : "75px"),
    [isMdDown, isSmDown],
  );
  const paragraphFontSize = useMemo(
    () => (isMdDown ? (isSmDown ? "20px" : "22px") : "28px"),
    [isMdDown, isSmDown],
  );
  const titleRef = useRef(null);
  const handleClick = useCallback(
    (categoryName) => {
      if (categoryName)
        axios
          .get(
            `${process.env.REACT_APP_BASE_URL}category/id-by-name/${categoryName}`,
          )
          .then((res) => {
            history.push(`/categories-individual/${res.data.id}`);
          })
          .catch((e) => history.push("/"));
      else history.push("/");
    },
    [history],
  );

  return (
    <Box>
      <Box
        height="95vh"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <Box width={isSmDown ? "90vw" : "75vw"} display="flex">
          <Grid container ref={titleRef} spacing={2} display="flex">
            <Grid item lg={12} md={12} sm={12} xs={12} overflow="hidden">
              <SlideUp
                containerRef={titleRef}
                content={
                  <Typography
                    fontWeight={600}
                    lineHeight={1.2}
                    fontSize={headerFontSize}
                    textTransform="capitalize"
                    pl={isMdDown ? 0 : 10}
                  >
                    Bifurcation
                  </Typography>
                }
              />
              <SlideUp
                containerRef={titleRef}
                content={
                  <Typography
                    fontWeight={600}
                    lineHeight={1.2}
                    fontSize={headerFontSize}
                    textTransform="capitalize"
                    pl={isMdDown ? 0 : 10}
                  >
                    based on
                  </Typography>
                }
              />
              <SlideUp
                containerRef={titleRef}
                content={
                  <Typography
                    fontWeight={600}
                    lineHeight={1.2}
                    fontSize={headerFontSize}
                    textTransform="capitalize"
                    pl={isMdDown ? 0 : 10}
                    pb={1}
                  >
                    categories
                  </Typography>
                }
              />
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Box>
        <img
          src={
            "https://darkhorsestocks.com/wp-content/uploads/2024/01/library_4x.jpg"
          }
          alt="Hero"
          className="hero-img"
        />
      </Box>
      <Box
        height="100vh"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <Box width={isSmDown ? "90vw" : "75vw"} display="flex">
          <Grid container spacing={2} display="flex">
            <Grid item lg={2} md={2} sm={3}>
              <Typography fontSize={20} fontWeight={600} lineHeight={1.2}>
                Overview
              </Typography>
            </Grid>
            <Grid item lg={8} md={10} sm={9}>
              <Grid container spacing={2} display="flex" flexDirection="column">
                <Grid item>
                  <Typography
                    fontSize={paragraphFontSize}
                    fontWeight={500}
                    lineHeight={1.2}
                  >
                    Companies at darkhorsestocks are bifurcated into various
                    different categories. Classification of these stocks in to
                    various categories is explained below.
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Box
        marginY="25vh"
        flexDirection="column"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Box width={isSmDown ? "85vw" : "75vw"}>
          <Grid
            container
            textTransform="capitalize"
            overflow="hidden"
            display="flex"
            flexDirection="column"
            mb={5}
          >
            <Grid item lg={8} md={12} sm={12}>
              <Typography
                fontWeight={600}
                lineHeight={1.5}
                fontSize={headerFontSize}
              >
                Darkhorsestocks
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            display="flex"
            flexDirection="column"
            alignItems="start"
            justifyContent="center"
            spacing={5}
          >
            <Grid item lg={8} md={12} sm={12}>
              <Typography
                fontWeight={300}
                fontSize={paragraphFontSize}
                lineHeight={1.2}
              >
                Darkhorsestocks is our prime category where every week one stock
                article is presented with all the relevant and introductory
                information. The company presented here is selected based on
                various suitable parameters and growth prospects we anticipate
                from the large pool of data . Normally companies suggested in
                this category are fundamentally strong and have proven
                consistent track record of growth. Some of the companies
                suggested under this category in past have delivered Phenomenal
                Returns.
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            marginY={2}
            display="flex"
            justifyContent="center"
            spacing={5}
          >
            <Grid item>
              <Button
                text="View all"
                onClick={() => handleClick("Darkhorsestocks")}
              />
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Box
        marginY="25vh"
        flexDirection="column"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Box width={isSmDown ? "85vw" : "75vw"}>
          <Grid
            container
            textTransform="capitalize"
            overflow="hidden"
            display="flex"
            flexDirection="column"
            mb={5}
          >
            <Grid item lg={8} md={12} sm={12}>
              <Typography
                fontWeight={600}
                lineHeight={1.5}
                fontSize={headerFontSize}
              >
                Infographics
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            display="flex"
            flexDirection="column"
            alignItems="start"
            justifyContent="center"
            spacing={5}
          >
            <Grid item lg={8} md={12} sm={12}>
              <Typography
                fontWeight={300}
                fontSize={paragraphFontSize}
                lineHeight={1.2}
              >
                This is the newest thing started by darkhorsestocks where we try
                to make investing more interactive and interesting. With
                Inforgraphic we present one old article which has already been
                suggested previously and in our view the article has missed the
                user attention or we would like users to focus more on the
                particular article as we belive the same could have a good
                potential upside possible. With Inforgraphic we try to cover the
                maximum and most important points in a summarized manner which
                would interest users to dig deeper into the stock. However we
                also advice users to read full research report for the said
                article presented via inforgraphic.
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            marginY={2}
            display="flex"
            justifyContent="center"
            spacing={5}
          >
            <Grid item>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={process.env.REACT_APP_INSTAGRAM_LINK}
              >
                <Button text="Instagram" />
              </a>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Box
        marginY="25vh"
        flexDirection="column"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Box width={isSmDown ? "85vw" : "75vw"}>
          <Grid
            container
            textTransform="capitalize"
            overflow="hidden"
            display="flex"
            flexDirection="column"
            mb={5}
          >
            <Grid item lg={8} md={12} sm={12}>
              <Typography
                fontWeight={600}
                lineHeight={1.5}
                fontSize={headerFontSize}
              >
                Holding company
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            display="flex"
            flexDirection="column"
            alignItems="start"
            justifyContent="center"
            spacing={5}
          >
            <Grid item lg={8} md={12} sm={12}>
              <Typography
                fontWeight={300}
                fontSize={paragraphFontSize}
                lineHeight={1.2}
              >
                Holding company are those companies which hold the shares of
                other companies. They are created specifically for the purpose
                of holding investments, creating layers of holding which may be
                feasible for tax saving purposes. Many a times the investment
                held by these companies are valued even more than the market cap
                of the holding companies but they are held with a view to never
                sell them.
              </Typography>
            </Grid>
            <Grid item lg={8} md={12} sm={12}>
              <Typography
                fontWeight={300}
                fontSize={paragraphFontSize}
                lineHeight={1.2}
              >
                So, holding companies can be considered as dead investments
                since the investments are subject to realization if any over
                very very long period. Therefore chances of holding companies
                delivering any meaning returns are negligible but they are worth
                knowing about or having I portfolio if you have a long term view
                of 5-10 years.
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            marginY={2}
            display="flex"
            justifyContent="center"
            spacing={5}
          >
            <Grid item>
              <Button
                text="View all"
                onClick={() => handleClick("Holding company")}
              />
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Box
        marginY="25vh"
        flexDirection="column"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Box width={isSmDown ? "85vw" : "75vw"}>
          <Grid
            container
            textTransform="capitalize"
            overflow="hidden"
            display="flex"
            flexDirection="column"
            mb={5}
          >
            <Grid item lg={8} md={12} sm={12}>
              <Typography
                fontWeight={600}
                lineHeight={1.5}
                fontSize={headerFontSize}
              >
                Penny company
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            display="flex"
            flexDirection="column"
            alignItems="start"
            justifyContent="center"
            spacing={5}
          >
            <Grid item lg={8} md={12} sm={12}>
              <Typography
                fontWeight={300}
                fontSize={paragraphFontSize}
                lineHeight={1.2}
              >
                As the name implies Incognito means hidden , is our category for
                Microcap/ Penny company. In India accounts are prepared in
                accordance with Accounting Standards which now are merged with
                newly introduced Indian Accounting Standards ( Ind AS). Normally
                accounts are prepared based on the concept of historical cost.
                As a result the Assets , Investments etc are presented based on
                the Lower of Fair Value or Historical cost which has a huge
                impact on the fundamental value of the company. Land for
                instance will always be undervalued under this concept of
                historical price base accounting.
              </Typography>
            </Grid>
            <Grid item lg={8} md={12} sm={12}>
              <Typography
                fontWeight={300}
                fontSize={paragraphFontSize}
                lineHeight={1.2}
              >
                Although under this category the articles presented do posses
                enormous value but have failed to deliver any meaning full
                returns. In fact this category has turned out to be failure in
                terms of the growth or returns since most of the articles
                presented under this category have failed to deliver any meaning
                full returns.
              </Typography>
            </Grid>
            <Grid item lg={8} md={12} sm={12}>
              <Typography
                fontWeight={300}
                fontSize={paragraphFontSize}
                lineHeight={1.2}
              >
                This articles are suggested for informational purpose only.
              </Typography>
              <Typography
                fontWeight={600}
                fontSize={paragraphFontSize}
                lineHeight={1.2}
                color="red"
              >
                Investing in this articles is extremely risky and could lead to
                complete loss of capital.
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            marginY={2}
            display="flex"
            justifyContent="center"
            spacing={5}
          >
            <Grid item>
              <Button
                text="View all"
                onClick={() => handleClick("Penny company")}
              />
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
}
