import { Slide } from "@mui/material";

export const SlideUp = ({ content, containerRef }) => {
  return (
    <Slide
      direction="up"
      in={true}
      container={containerRef?.current}
      timeout={2000}
    >
      {content}
    </Slide>
  );
};
