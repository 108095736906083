import React, { useEffect, useMemo, useRef, useState } from "react";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import "./special-list-style.scss"; // Import the CSS file for custom styles
import SecImage from "../../Assets/image section.png";
import { useGetSpecialListQuery } from "../../redux/HomeAPI";
import SkeletonSlider from "../../Components/Skeleton card/SkeletonSlider";
import LinearProgressBar from "../../Components/LinearProgressBar";
import { Box, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import { SlideUp } from "../../Components/SlideUp";
import CardList from "../../Components/CardList";

const SpecialList = () => {
  const { listType } = useParams();
  const { data, isLoading, isSuccess } = useGetSpecialListQuery(listType);
  const [companyList, setCompanyList] = useState();
  useEffect(() => data && setCompanyList(data.companyList), [data]);
  const containerRef = useRef(null);
  const theme = useTheme();
  const isSmDown = useMediaQuery(theme.breakpoints.down("sm"));

  const isMdDown = useMediaQuery(theme.breakpoints.down("md"));
  const headerFontSize = useMemo(
    () => (isMdDown ? (isSmDown ? "30px" : "45px") : "75px"),
    [isMdDown, isSmDown],
  );

  const paragraphFontSize = useMemo(
    () => (isMdDown ? (isSmDown ? "20px" : "22px") : "28px"),
    [isMdDown, isSmDown],
  );

  return (
    <Box display="flex" justifyContent="center" alignItems="center">
      <Box>
        {isLoading && !isSuccess ? (
          <>
            <LinearProgressBar />
            <SkeletonSlider />
          </>
        ) : (
          companyList && (
            <>
              <Box
                height="100vh"
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <Box width={isSmDown ? "90vw" : "75vw"} display="flex">
                  <Grid container spacing={2} display="flex">
                    <Grid
                      item
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                      ref={containerRef}
                      overflow="hidden"
                    >
                      <SlideUp
                        containerRef={containerRef}
                        content={
                          <Typography
                            fontWeight={600}
                            lineHeight={1.5}
                            fontSize={headerFontSize}
                            textTransform="capitalize"
                            pl={isMdDown ? 0 : 10}
                          >
                            {data.title}
                          </Typography>
                        }
                      />
                    </Grid>
                  </Grid>
                </Box>
              </Box>
              <Box
                height="100vh"
                width="100vw"
                sx={{
                  backgroundImage: `url(${companyList[0].image || SecImage})`,
                  backgroundAttachment: "fixed",
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                }}
              ></Box>
              <Box
                height="100vh"
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <Box width={isSmDown ? "90vw" : "75vw"} display="flex">
                  <Grid container spacing={2} display="flex">
                    <Grid item lg={2} md={2} sm={3}>
                      <Typography
                        fontSize={20}
                        fontWeight={600}
                        lineHeight={1.2}
                      >
                        Overview
                      </Typography>
                    </Grid>
                    <Grid item lg={8} md={10} sm={9}>
                      <Typography
                        fontSize={paragraphFontSize}
                        fontWeight={500}
                        lineHeight={1.2}
                      >
                        This page showcases the most recent suggestions from
                        DarkHorseStocks. You can easily sort the featured
                        companies by date, price, and gains, in both ascending
                        and descending order.
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
              </Box>

              <CardList
                contentList={companyList}
                cursorData="Read report"
                isCompany={true}
                isLoading={isLoading}
                isSuccess={isSuccess}
              />
            </>
          )
        )}
      </Box>
    </Box>
  );
};

export default SpecialList;
