import { Box, Grid } from "@mui/material";
import React from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Button from "../../../Components/Button/Button";

const Admin = () => {
  const history = useHistory();
  return (
    <Box display="flex" alignItems="center" justifyContent="center">
      <Box
        width="90vw"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Grid
          mt={16}
          mb={16}
          container
          display="flex"
          alignItems="center"
          justifyContent="center"
          spacing={4}
        >
          <Grid
            item
            xs={12}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Grid
              container
              display="flex"
              alignItems="center"
              justifyContent="center"
              spacing={4}
            >
              <Grid
                item
                xs={12}
                sm={4}
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <Button
                  onClick={() => history.push("/admin-home-page")}
                  text="Home-Page"
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={4}
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <Button
                  onClick={() => history.push("/admin-list-companies")}
                  text="Companies"
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={4}
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <Button
                  onClick={() => history.push("/admin-users")}
                  text="Users"
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Grid
              container
              display="flex"
              alignItems="center"
              justifyContent="center"
              spacing={4}
            >
              <Grid
                item
                xs={12}
                sm={4}
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <Button
                  onClick={() => history.push("/admin-list-tags")}
                  text="Tags"
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={4}
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <Button
                  onClick={() => history.push("/admin-list-sectors")}
                  text="Sectors"
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={4}
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <Button
                  onClick={() => history.push("/admin-list-categories")}
                  text="Categories"
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Grid
              container
              display="flex"
              alignItems="center"
              justifyContent="center"
              spacing={4}
            >
              <Grid
                item
                sm={4}
                xs={12}
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <Button
                  onClick={() => history.push("/admin-list-plans")}
                  text="Plans"
                />
              </Grid>
              <Grid
                item
                sm={4}
                xs={12}
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <Button
                  onClick={() => history.push("/admin-list-apps")}
                  text="Apps"
                />
              </Grid>
              <Grid
                item
                sm={4}
                xs={12}
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <Button
                  onClick={() => history.push("/admin-inserted-users")}
                  text="Inserted Users"
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default Admin;
