import React, { useMemo, useRef } from "react";
import HeroImg from "../../Assets/hereo.png";
import SecImage from "../../Assets/image section.png";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";

import "./style.scss";
import { Box, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import { SlideUp } from "../../Components/SlideUp";

export default function PhilosophyPage() {
  const theme = useTheme();
  const isSmDown = useMediaQuery(theme.breakpoints.down("sm"));
  const isMdDown = useMediaQuery(theme.breakpoints.down("md"));
  const headerFontSize = useMemo(
    () => (isMdDown ? (isSmDown ? "30px" : "45px") : "75px"),
    [isMdDown, isSmDown],
  );
  const paragraphFontSize = useMemo(
    () => (isMdDown ? (isSmDown ? "20px" : "22px") : "28px"),
    [isMdDown, isSmDown],
  );
  const titleRef = useRef(null);
  return (
    <Box>
      <Box
        height="95vh"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <Box width={isSmDown ? "90vw" : "75vw"} display="flex">
          <Grid container ref={titleRef} spacing={2} display="flex">
            <Grid item lg={12} md={12} sm={12} xs={12} overflow="hidden">
              <SlideUp
                containerRef={titleRef}
                content={
                  <Typography
                    fontWeight={600}
                    lineHeight={1.5}
                    fontSize={headerFontSize}
                    textTransform="capitalize"
                    pl={isMdDown ? 0 : 10}
                  >
                    Investment Philosophy
                  </Typography>
                }
              />
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Box>
        <img src={HeroImg} alt="Hero" className="hero-img" />
      </Box>
      <Box
        height="100vh"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <Box width={isSmDown ? "90vw" : "75vw"} display="flex">
          <Grid container spacing={2} display="flex">
            <Grid item lg={2} md={2} sm={3}>
              <Typography fontSize={20} fontWeight={600} lineHeight={1.2}>
                Overview
              </Typography>
            </Grid>
            <Grid item lg={8} md={10} sm={9}>
              <Grid container spacing={2} display="flex" flexDirection="column">
                <Grid item>
                  <Typography
                    fontSize={paragraphFontSize}
                    fontWeight={500}
                    lineHeight={1.2}
                  >
                    Lot of users have been repeatedly asking about the various
                    strategies or the methodologies we follow at Darkhorsestocks
                    that lead us to such amazing articles. Although there is no
                    secret behind it, you need to understand that just by
                    looking at these points, it will not help you find the next
                    multibagger article.
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    fontSize={paragraphFontSize}
                    fontWeight={500}
                    lineHeight={1.2}
                  >
                    Most of the analysts at darkhorsestocks have vast experience
                    in the market, with professional qualifications, and, on top
                    of that, they are associated with some of the major
                    investment banking firms. As a result, we are able to
                    provide you with articles that are backed up by conviction.
                    Despite that, there have been times when we have been wrong,
                    and we are not ashamed of admitting our mistakes.
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    fontSize={paragraphFontSize}
                    fontWeight={500}
                    lineHeight={1.2}
                  >
                    Thus, the below list is not exhaustive; instead, these are
                    some of the common ones to start with. Other than this,
                    there are a number of different scenarios which keep on
                    varying from one company to another or from one sector to
                    another etc.
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Box
        marginY="20vh"
        flexDirection="column"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Box width={isSmDown ? "85vw" : "75vw"}>
          <Grid
            container
            textTransform="capitalize"
            overflow="hidden"
            display="flex"
            flexDirection="column"
            mb={5}
          >
            <Grid item lg={6} md={12} sm={12}>
              <Typography
                fontWeight={600}
                lineHeight={1.2}
                fontSize={headerFontSize}
              >
                Financlial parameters
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            display="flex"
            flexDirection="column"
            alignItems="start"
            justifyContent="center"
            spacing={5}
          >
            <Grid item lg={10} md={12} sm={12}>
              <Grid container spacing={1} display="flex" flexDirection="column">
                <Grid item ml={2}>
                  <Typography
                    fontWeight={300}
                    fontSize={paragraphFontSize}
                    lineHeight={1.2}
                    display="flex"
                    alignItems="start"
                  >
                    <FiberManualRecordIcon
                      fontSize="small"
                      sx={{ marginRight: 1, marginTop: 1 }}
                    />
                    Piotroski score {">"} 7
                  </Typography>
                </Grid>
                <Grid item ml={2}>
                  <Typography
                    fontWeight={300}
                    fontSize={paragraphFontSize}
                    lineHeight={1.2}
                    display="flex"
                    alignItems="start"
                  >
                    <FiberManualRecordIcon
                      fontSize="small"
                      sx={{ marginRight: 1, marginTop: 1 }}
                    />
                    EPS latest quarter {">"} 1.2 * EPS preceding year quarter
                  </Typography>
                </Grid>
                <Grid item ml={2}>
                  <Typography
                    fontWeight={300}
                    fontSize={paragraphFontSize}
                    lineHeight={1.2}
                    display="flex"
                    alignItems="start"
                  >
                    <FiberManualRecordIcon
                      fontSize="small"
                      sx={{ marginRight: 1, marginTop: 1 }}
                    />
                    100*((High price - Current price )/High price) {"<"} 10
                  </Typography>
                </Grid>
                <Grid item ml={2}>
                  <Typography
                    fontWeight={300}
                    fontSize={paragraphFontSize}
                    lineHeight={1.2}
                    display="flex"
                    alignItems="start"
                  >
                    <FiberManualRecordIcon
                      fontSize="small"
                      sx={{ marginRight: 1, marginTop: 1 }}
                    />
                    Contingent liabilities {"<"} Net Profit last year
                  </Typography>
                </Grid>
                <Grid item ml={2}>
                  <Typography
                    fontWeight={300}
                    fontSize={paragraphFontSize}
                    lineHeight={1.2}
                    display="flex"
                    alignItems="start"
                  >
                    <FiberManualRecordIcon
                      fontSize="small"
                      sx={{ marginRight: 1, marginTop: 1 }}
                    />
                    (Sales growth 3Years {">"} 12% AND Net block{" >"} Net block
                    3Years back * 2) OR (Net block + Capital work in progress ){" "}
                    {">"} 1.5 * (Net block preceding year + Capital work in
                    progress preceding year )
                  </Typography>
                </Grid>
                <Grid item ml={2}>
                  <Typography
                    fontWeight={300}
                    fontSize={paragraphFontSize}
                    lineHeight={1.2}
                    display="flex"
                    alignItems="start"
                  >
                    <FiberManualRecordIcon
                      fontSize="small"
                      sx={{ marginRight: 1, marginTop: 1 }}
                    />
                    Tax latest quarter {">"} .10 * Net Profit latest quarter,
                    PEG Ratio
                    {">"} 2
                  </Typography>
                </Grid>
                <Grid item ml={2}>
                  <Typography
                    fontWeight={300}
                    fontSize={paragraphFontSize}
                    lineHeight={1.2}
                    display="flex"
                    alignItems="start"
                  >
                    <FiberManualRecordIcon
                      fontSize="small"
                      sx={{ marginRight: 1, marginTop: 1 }}
                    />
                    DMA 200 {">"} Current price *0.95, G Factor {">"} 1
                  </Typography>
                </Grid>
                <Grid item ml={2}>
                  <Typography
                    fontWeight={300}
                    fontSize={paragraphFontSize}
                    lineHeight={1.2}
                    display="flex"
                    alignItems="start"
                  >
                    <FiberManualRecordIcon
                      fontSize="small"
                      sx={{ marginRight: 1, marginTop: 1 }}
                    />
                    Altman Z Score {">"} 3
                  </Typography>
                </Grid>
                <Grid item ml={2}>
                  <Typography
                    fontWeight={300}
                    fontSize={paragraphFontSize}
                    lineHeight={1.2}
                    display="flex"
                    alignItems="start"
                  >
                    <FiberManualRecordIcon
                      fontSize="small"
                      sx={{ marginRight: 1, marginTop: 1 }}
                    />
                    (OPM last year + OPM preceding year) {">"} 15 AND (NPM last
                    year + NPM preceding year) {">"} 8 AND ((Debtor days{" <"}{" "}
                    60) OR ((Inventory turnover ratio * NPM last year){" >"} 100
                    )) PB X PE
                    {"<="} 22.5
                  </Typography>
                </Grid>
                <Grid item ml={2}>
                  <Typography
                    fontWeight={300}
                    fontSize={paragraphFontSize}
                    lineHeight={1.2}
                    display="flex"
                    alignItems="start"
                  >
                    <FiberManualRecordIcon
                      fontSize="small"
                      sx={{ marginRight: 1, marginTop: 1 }}
                    />
                    Debt/ Number of equity shares {"< "}2 * NCAVPS
                  </Typography>
                </Grid>
                <Grid item ml={2}>
                  <Typography
                    fontWeight={300}
                    fontSize={paragraphFontSize}
                    lineHeight={1.2}
                    display="flex"
                    alignItems="start"
                  >
                    <FiberManualRecordIcon
                      fontSize="small"
                      sx={{ marginRight: 1, marginTop: 1 }}
                    />
                    Working capital / Sales {"<"} 0.25
                  </Typography>
                </Grid>
                <Grid item ml={2}>
                  <Typography
                    fontWeight={300}
                    fontSize={paragraphFontSize}
                    lineHeight={1.2}
                    display="flex"
                    alignItems="start"
                  >
                    <FiberManualRecordIcon
                      fontSize="small"
                      sx={{ marginRight: 1, marginTop: 1 }}
                    />
                    (Market Capitalization {">"} 15) AND (Sales latest quarter{" "}
                    {">"}
                    Sales preceding quarter) AND ((Net Profit latest quarter /
                    Net Profit preceding quarter ) {">"} 1.5)
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Box
        height="95vh"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <Box width={isSmDown ? "90vw" : "75vw"} display="flex">
          <Grid
            container
            textAlign="center"
            spacing={8}
            display="flex"
            justifyContent="center"
          >
            <Grid item lg={7} md={12} sm={12} xs={12}>
              <Typography
                fontWeight={600}
                lineHeight={1.2}
                fontSize={headerFontSize}
                textTransform="capitalize"
              >
                Did you really believed all just we said above? 🤨
              </Typography>
            </Grid>
            <Grid item lg={8} md={12} sm={12} xs={12}>
              <Typography
                fontWeight={600}
                lineHeight={1.2}
                fontSize={paragraphFontSize}
                textTransform="capitalize"
              >
                Well, It was all just a load of crap. 💩
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Box>

      <Box
        height="100vh"
        width="100vw"
        sx={{
          backgroundImage: `url("${SecImage}")`,
          backgroundAttachment: "fixed",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      ></Box>
      <Box
        marginY="20vh"
        flexDirection="column"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Box width={isSmDown ? "85vw" : "75vw"}>
          <Grid
            container
            textTransform="capitalize"
            overflow="hidden"
            display="flex"
            flexDirection="column"
            mb={5}
          >
            <Grid item lg={6} md={12} sm={12}>
              <Typography
                fontWeight={600}
                lineHeight={1.2}
                fontSize={headerFontSize}
              >
                What will you get?
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            display="flex"
            flexDirection="column"
            alignItems="start"
            justifyContent="center"
            spacing={5}
          >
            <Grid item>
              <Typography
                fontWeight={300}
                fontSize={paragraphFontSize}
                lineHeight={1.2}
              >
                We try invest in companies with the following characteristics:
              </Typography>
            </Grid>
            <Grid item ml={2} lg={8} md={12} sm={12}>
              <Grid container spacing={2} display="flex" flexDirection="column">
                <Grid item>
                  <Typography
                    fontWeight={300}
                    fontSize={paragraphFontSize}
                    lineHeight={1.2}
                    display="flex"
                    alignItems="start"
                  >
                    <FiberManualRecordIcon
                      fontSize="small"
                      sx={{ marginRight: 1, marginTop: 1 }}
                    />
                    Companies based on the fundamentals or the strong businesses
                    trading at deep discount.
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    fontWeight={300}
                    fontSize={paragraphFontSize}
                    lineHeight={1.2}
                    display="flex"
                    alignItems="start"
                  >
                    <FiberManualRecordIcon
                      fontSize="small"
                      sx={{ marginRight: 1, marginTop: 1 }}
                    />
                    Small companies having significantly high market share in
                    niche sectors with very less competition.
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    fontWeight={300}
                    fontSize={paragraphFontSize}
                    lineHeight={1.2}
                    display="flex"
                    alignItems="start"
                  >
                    <FiberManualRecordIcon
                      fontSize="small"
                      sx={{ marginRight: 1, marginTop: 1 }}
                    />
                    Companies with cyclical business, currently in the trough or
                    the recovery phase of its business cycle.
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    fontWeight={300}
                    fontSize={paragraphFontSize}
                    lineHeight={1.2}
                    display="flex"
                    alignItems="start"
                  >
                    <FiberManualRecordIcon
                      fontSize="small"
                      sx={{ marginRight: 1, marginTop: 1 }}
                    />
                    Companies operating at very low capacity where utilisation
                    is at very low levels given its total capacity.
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    fontWeight={300}
                    fontSize={paragraphFontSize}
                    lineHeight={1.2}
                    display="flex"
                    alignItems="start"
                  >
                    <FiberManualRecordIcon
                      fontSize="small"
                      sx={{ marginRight: 1, marginTop: 1 }}
                    />
                    Companies where strong inflow of orders would be expected in
                    the coming future as explained by the management backed by
                    strong evidence of the demand of the product company is
                    manufacturing.
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    fontWeight={300}
                    fontSize={paragraphFontSize}
                    lineHeight={1.2}
                    display="flex"
                    alignItems="start"
                  >
                    <FiberManualRecordIcon
                      fontSize="small"
                      sx={{ marginRight: 1, marginTop: 1 }}
                    />
                    Companies where there is significant capex taking place to
                    ramp up the manufacturing / production facilities.
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    fontWeight={300}
                    fontSize={paragraphFontSize}
                    lineHeight={1.2}
                    display="flex"
                    alignItems="start"
                  >
                    <FiberManualRecordIcon
                      fontSize="small"
                      sx={{ marginRight: 1, marginTop: 1 }}
                    />
                    Companies, despite operating monopolistic market with high
                    product differentiation because these companies can easily
                    raise prices without hindering the demand of their products.
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    fontWeight={300}
                    fontSize={paragraphFontSize}
                    lineHeight={1.2}
                    display="flex"
                    alignItems="start"
                  >
                    <FiberManualRecordIcon
                      fontSize="small"
                      sx={{ marginRight: 1, marginTop: 1 }}
                    />
                    Companies which spending very less on advertisements.
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    fontWeight={300}
                    fontSize={paragraphFontSize}
                    lineHeight={1.2}
                    display="flex"
                    alignItems="start"
                  >
                    <FiberManualRecordIcon
                      fontSize="small"
                      sx={{ marginRight: 1, marginTop: 1 }}
                    />
                    Companies where promoter holding is very high where it can
                    be seen that the interests of the promoters are aligned with
                    that of the shareholders.
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    fontWeight={300}
                    fontSize={paragraphFontSize}
                    lineHeight={1.2}
                    display="flex"
                    alignItems="start"
                  >
                    <FiberManualRecordIcon
                      fontSize="small"
                      sx={{ marginRight: 1, marginTop: 1 }}
                    />
                    Companies in which it is expected to be a radical change in
                    the management where the reins of the business are handed
                    over to the next generation to take it to the new heights.
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item lg={10} md={12} sm={12}>
              <Typography
                fontWeight={300}
                fontSize={paragraphFontSize}
                lineHeight={1.2}
              >
                Above mentioned are some of the points we try to look into
                companies but these <b>list is not exhaustive. </b> Also there
                could be companies that we might have suggested in past which
                would oppose our own investment philosophy but the same would
                have been supported by other factors which we might have looked
                into at the time of carrying on the research.
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Box
        height="100vh"
        flexDirection="column"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Box width={isSmDown ? "85vw" : "75vw"}>
          <Grid
            container
            textTransform="capitalize"
            overflow="hidden"
            display="flex"
            flexDirection="column"
            mb={5}
          >
            <Grid item lg={6} md={12} sm={12}>
              <Typography
                fontWeight={600}
                lineHeight={1.2}
                fontSize={headerFontSize}
              >
                In the end ✨
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            display="flex"
            flexDirection="column"
            alignItems="start"
            justifyContent="center"
            spacing={5}
          >
            <Grid item lg={8} md={12} sm={12}>
              <Typography
                fontWeight={500}
                fontSize={paragraphFontSize}
                lineHeight={1.2}
              >
                The ❤️ of our investment strategy is centred on producing
                consistent alpha and, more crucially, identifying companies that
                are not on the radar of most analysts. To do so, we supplement
                our in-depth industry expertise with rigorous primary research,
                cutting-edge technology, and in-house unique tools to uncover
                insights and provide value.
              </Typography>
            </Grid>
            <Grid item lg={8} md={12} sm={12}>
              <Typography
                fontWeight={500}
                fontSize={paragraphFontSize}
                lineHeight={1.2}
              >
                Our team includes some of the most prestigious research
                analysts, fund managers / portfolio managers, and other
                professionals, making it the most effective team of its kind in
                the Industry. In order to uncover the best stocks for our users,
                our team carries out its own independent and bottom-up analysis,
                which includes studying business annual reports and analysing
                them in depth. 😊
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
}
